import { defineComponent, ref } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import { GChart } from "vue-google-charts";
import '@vuepic/vue-datepicker/dist/main.css';
export default defineComponent({
  name: 'InvestorsView',
  components: {
    Datepicker,
    GChart
  },
  props:{
    token:{
      type: String,
      default: ""
    }
  },
  setup(props) {
    const date = ref(new Date());
    const chartData = ref([]);
    const loaded = ref(false);
    const dateOptions = (date) => {
      let day = (date.getDate() < 10) ? "0" + date.getDate() : date.getDate();
      let month = (date.getMonth() < 9) ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
      return "" + date.getFullYear() + '-' + month + '-' + day;
    }
    const get_investors = () => {
      fetch(
        'https://7k6gnwamhi-sureti-admin-api.oiti.cloud:81/admin/metrics/investors_data?date=' + dateOptions(date.value),{
        headers: {
          'Authorization': `Bearer ${props.token}`,
        },
      })
        .then(res => res.json())
        .then(data => {
          chartData.value = data.data;
          if (Object.keys(data).length  != 0){
            loaded.value = true;
          }
        })
        .catch(err => console.log(err.message));
    }
    const downloadCSVData = () => {
      let csv = chartData.value[0].toString()+"\n";
      let justData = chartData.value.slice(1);
      justData.forEach((row) => {
        csv += row.join(',');
        csv += "\n";
      });

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'Investors.csv';
      anchor.click();
    }
    const cssClassNames = {
      headerCell: 'header-cell',
      tableRow: 'table-row',
      oddTableRow: 'odd-table-row',
      tableCell: 'table-cell'
    }
    const chartOptions1 = {
      width: "100%",
      height: 600,
      'cssClassNames': cssClassNames,
    }

    return {
      date,
      chartData,
      chartOptions1,
      loaded,
      get_investors,
      downloadCSVData
    }
  }
});
