<div class="su_metrics_dates">
  <div class="su_metrics_dates_single">
    <p>Seleccione la fecha a consultar:</p>
    <Datepicker v-model="date" :enableTimePicker="false" :minDate="new Date(2021,0)" :maxDate="new Date()"/>
  </div>
  <button @click="get_investors">Traer Métrica Inversionistas</button>
</div>
<div class="su_graphs">
  <div class="su_graphs_graph">
    <GChart type="Table" :data="chartData" :options="chartOptions1" />
    <button v-if="loaded" @click="downloadCSVData">Descargar CSV</button>
  </div>
</div>