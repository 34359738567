<div class="su_metrics_dates">
  <button class="cohorts_button" @click="get_cohorts">Traer Cohortes</button>
  <div class="su_metrics_dates_filter">
    <div class="su_metrics_dates_single">
      <p>Seleccione el mes inicial:</p>
      <Datepicker v-model="initial_month" monthPicker :minDate="new Date(2021,0)" :maxDate="max_month" />
    </div>
    <div class="from-to">
      <p>-></p>
    </div>
    <div class="su_metrics_dates_single">
      <p>Seleccione el mes final:</p>
      <Datepicker v-model="final_month" monthPicker :minDate="new Date(2021,0)" :maxDate="max_month" />
    </div>
    <div class="from-to">
      <p>-></p>
    </div>
    <button v-if="loaded" @click="filter_cohorts">Filtrar</button>
  </div>
</div>
<div v-if="loaded">
  <div class="su_graphs_graph">
    <h2>Prestamos - Promedio Días de Atraso - <span>Table</span></h2>
    <GChart type="Table" :data="investorsChartData" :options="chartOptions1" />
  </div>
  <div class="su_graphs_graph">
    <h2>Prestamos - Promedio Días de Atraso</h2>
    <GChart type="LineChart" :data="invLineChartData" :options="chartOptions2" />
  </div>
  <button @click="downloadCSVInvestors">Descargar CSV</button>
</div>