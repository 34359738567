<div class="su_metrics_dates">
  <div class="su_metrics_dates_single">
    <p>semana inicial:</p>
    <Datepicker v-model="initial_date" :enableTimePicker="false" weekPicker :minDate="new Date(2021,0)" :maxDate="new Date()"/>
  </div>
  <div class="from-to"><p>-></p></div>
  <div class="su_metrics_dates_single">
    <p>semana final:</p>
    <Datepicker v-model="final_date" :enableTimePicker="false" weekPicker :minDate="new Date(2021,0)" :maxDate="new Date()"/>
  </div>
  <div class="from-to"><p>-></p></div>
  <button @click="get_other_metrics">Traer Métricas Individuales</button>
</div>
<p v-if="loaded">Total de Nuevos Inversores: {{new_investors}}</p>
<p v-if="loaded">Total de Inversionistas Promedio Préstamo: {{investors_by_loan_mean}}</p>
<div class="su_graphs">
  <div class="su_graphs_graph">
    <GChart type="Table" :data="chartData" :options="chartOptions1" />
    <button v-if="loaded" @click="downloadCSVData">Descargar CSV</button>
  </div>
</div>