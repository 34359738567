import { defineComponent, ref } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import { GChart } from "vue-google-charts";
import '@vuepic/vue-datepicker/dist/main.css';
export default defineComponent({
  name: 'OtherMetricsView',
  components: {
    Datepicker,
    GChart
  },
  props: {
    token: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const initial_date = ref();
    const final_date = ref();
    const chartData = ref([["monday_date", "new_investors", "investors_by_loan_mean"]]);
    const loaded = ref(false);
    const new_investors = ref(2);
    const investors_by_loan_mean = ref(5);

    const downloadCSVData = () => {
      let csv = chartData.value[0].toString() + "\n";
      let justData = chartData.value.slice(1);
      justData.forEach((row) => {
        csv += row.join(',');
        csv += "\n";
      });
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'Individuals.csv';
      anchor.click();
    }
    const dateOptions = (date) => {
        let day = (date.getDate() < 10) ? "0" + date.getDate() : date.getDate();
        let month = (date.getMonth() < 9) ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        return "" + date.getFullYear() + '-' + month + '-' + day;
      }
    const get_other_metrics = () => {
      fetch(
        'https://7k6gnwamhi-sureti-admin-api.oiti.cloud:81/admin/metrics/investor_individuals?lower_date='+dateOptions(initial_date.value[0]) + '&higher_date=' + dateOptions(final_date.value[1]), 
        {
        headers: {
          'Authorization': `Bearer ${props.token}`,
        },
      })
        .then(res => res.json())
        .then(data => {
          if (Object.keys(data).length  != 0){
            loaded.value = true;
          }
          chartData.value = data.data.data_by_week;
          new_investors.value = data.data.total_new_investors;
          investors_by_loan_mean.value = data.data.total_investors_by_loan_mean;
          
        })
        .catch(err => console.log(err.message));
    }
    const cssClassNames = {
      headerCell: 'header-cell',
      tableRow: 'table-row',
      oddTableRow: 'odd-table-row',
      tableCell: 'table-cell'
    }
    const chartOptions1 = {
      width: "100%",
      height: 600,
      'cssClassNames': cssClassNames,
    }

    return {
      initial_date,
      final_date,
      new_investors,
      investors_by_loan_mean,
      chartData,
      loaded,
      chartOptions1,
      get_other_metrics,
      downloadCSVData
    }
  }
});
