import { defineComponent, ref } from "vue";
import { GChart } from "vue-google-charts";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default defineComponent({
  name: "CohortsView",

  components: {
    GChart,
    Datepicker
  },
  
  props: {
    token: {
      type: String,
      default: ""
    }
  },
  
  setup(props) {
    const investorsChartData = ref([[]]);
    const meanChartData = ref([[]]);
    const totalInvChartData = ref([[]]);
    const totalMeanChartData = ref([[]]);
    const invLineChartData = ref([["initial_empty"], ['0']]);
    const meanLineChartData = ref([["initial_empty"], ['0']]);
    const loaded = ref(false);
    const month_names = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"]
    let data_indexes= {};
    
    const get_indexes = (data) =>{
      for (let i = 1; i < data.length; i++) {
        data_indexes[data[i][0].replace(" ","")] = i;
      }
    }
    
    const get_cohorts = () => {
      fetch(
        'https://7k6gnwamhi-sureti-admin-api.oiti.cloud:81/admin/metrics/client_cohorts',{
        headers: {
          'Authorization': `Bearer ${props.token}`,
        },
      })
        .then(res => res.json())
        .then(data => {
          
          let cohortData = data.data.delay_interest_payment_days
          investorsChartData.value = cohortData;
          totalInvChartData.value = cohortData;
          
          get_indexes(cohortData);
          
          if (Object.keys(cohortData).length  != 0){
            loaded.value = true;
          }
          invLineChartData.value = data_modifier(investorsChartData.value);
          meanLineChartData.value = data_modifier(meanChartData.value);
        })
        .catch(err => {
          console.log(err.message);
          loaded.value = false;
        });
    }

    const downloadCSVInvestors = () => {
      downloadCSVData(investorsChartData, "investors_cohort.csv")
    }
    
    const downloadCSVMean = () => {
      downloadCSVData(meanChartData, "mean_investments_cohort.csv")
    }
    
    const downloadCSVData = (chartData, filename) => {
      let csv = chartData.value[0].toString() + "\n";
      let justData = chartData.value.slice(1);
      justData.forEach((row) => {
        csv += row.join(',');
        csv += "\n";
      });
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = filename;
      anchor.click();
    }
    
    const filter_cohorts= () =>{
      let year_difference = final_month.value.year - initial_month.value.year;
      let month_difference = (year_difference * 12) + final_month.value.month - initial_month.value.month;
      let key = month_names[initial_month.value.month]+","+initial_month.value.year;
      let start_index = data_indexes[key];
      investorsChartData.value = [["Mes"]];
      meanChartData.value = [["Mes"]];
      for (let i = 0; i < month_difference; i++) {
        investorsChartData.value[0].push(""+i);
        meanChartData.value[0].push(""+i);
        let partial_row_inv = totalInvChartData.value[start_index+i].slice(0,month_difference+1);
        let partial_row_mean = totalMeanChartData.value[start_index+i].slice(0,month_difference+1);
        for (let j = 0; j < i; j++) {
          partial_row_inv[month_difference-j] = null;
          partial_row_mean[month_difference-j] = null;
        }
        investorsChartData.value.push(partial_row_inv);
        meanChartData.value.push(partial_row_mean);
      }
      invLineChartData.value = data_modifier(investorsChartData.value);
      meanLineChartData.value = data_modifier(meanChartData.value);             
    }

    const data_modifier = (data) => {
      let data_modified = [];
      for (let i = 0; i < data.length; i++) {
        let data_row = [];
        for (let j = 0; j < data[i].length; j++) {
          data_row.push(data[j][i]);
        }
        data_modified.push(data_row)
      }
      return data_modified
    }

    const cssClassNames = {
      headerCell: 'header-cell',
      tableRow: 'table-row',
      oddTableRow: 'odd-table-row',
      tableCell: 'table-cell'
    }

    const chartOptions1 = {
      width: "100%",
      height: 600,
      'cssClassNames': cssClassNames,
    }

    const chartOptions2 = {
      backgroundColor: '#F5F4FF',
      width: "100%",
      height: 600,
      chartArea: { left: 40, right: 120, top: 40, bottom: 40, width: "95%" },
      lineDashStyle: [4, 4],
      axes: {
        x: {
          0: { side: 'bottom' }
        }
      }
    }
    
    const initial_month = ref({
      month: 0,
      year: 2021
    });
    const max_month = new Date(new Date().getFullYear(), new Date().getMonth())
    const final_month = ref({
      month: new Date().getMonth()-1,
      year: new Date().getFullYear()
    });
    
    return {
      chartOptions1,
      chartOptions2,
      investorsChartData,
      invLineChartData,
      meanChartData,
      meanLineChartData,
      final_month,
      initial_month,
      loaded,
      max_month,
      get_cohorts,
      filter_cohorts,
      downloadCSVInvestors,
      downloadCSVMean
    };
  },
});  
